import React from 'react';



let YandexMap = (props)=>{

	return (

		<iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3A0536078fd7413b40ee6c1ec14a1d219d7bf45ee732531b2fbc0369af82c978a2&amp;source=constructor" width="500" height="400" frameborder="0"></iframe>
		)

}




let ContactDetails = (props)=>{


	return (
			<div className="contacts-details" id="contacts">

				<span className="address">ООО "Безопасность информационных технологий"</span>
				<span className="address">ИНН 6154134069</span>

				<div className="contacts-details--logo">
					<img src="img/icon-ok-01.svg" className="contacts-details--logo--image" alt=""/>
				</div>
				<span className="phone">
					<a href="tel:+7 (8634) 38-62-81">+7 (8634) 38-62-81</a>
					<br/>
				</span>
				<span className="address">347942, Ростовская область, г.Таганрог, <br/>ул. Поляковское шоссе, 16 офис 412-3
					
				</span>
				<span className="email">
					<a href="mailto:info@rndbit.ru">info@rndbit.ru</a>
					<br/>
				</span>
			</div>

		)

}

export default function Contacts(props){

	return (

		<section className="contacts__section">
			<div className="contacts__section--container">
				<YandexMap/>
				<ContactDetails/>
			</div>
		</section>
		)
}
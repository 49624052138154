import React from 'react';
import ReactDOM from 'react-dom';


import Header from "./Header";
import InfoSection from "./InfoSection";
import Contacts from "./Contacts";
import Footer from "./Footer";
import Partners from "./Partners";
import Services from './Services';

import {Context} from "./OurContext";




import Carousel from './Carousel';

// const Carousel = lazy( ()=>import('./Carousel') );



ReactDOM.render( 
	<>
	<Context.Provider value={{}}>
		<Header logoSrc="./img/logo-main_logo-main.png" logoLink="#"/>
		<Partners/>
		<Services/>
		<InfoSection/>
		<Contacts/>
		<Footer/>
	</Context.Provider>
	</>
	, document.getElementById('root'));


import React from "react";
import "./Services.css"

const Services = ()=>{


    return (

    <table id="table" class="table table-hover table-mc-light-blue">
        <thead>
        <tr>
        <th>Услуга</th>
        <th>Описание</th>
        </tr>
      </thead>
      <tbody>
        <tr>
            
        </tr>
        <tr>
            <td>ИТ-инфраструктура</td>
            <td>
                <ul>
                    <li>Архитектурные решения на лучшем ПО</li>
                    <li>Информационная безопасность</li>
                    <li>Управление ИТ-проектами</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Управление проектами</td>
            <td>
                <ul>
                    <li>Контроль трудозатрат, бюджетов, сроков</li>
                    <li>Координация работы участников</li>
                    <li>Проектный подход в ИТ-проектах</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Облачные сервисы</td>
            <td>
                <ul>
                    <li>Миграция в облако</li>
                    <li>Стратегия перехода на облако</li>
                    <li>Резервное копирование и восстановление</li>
                    <li>Частное облако и Colocation</li>
                    <li>Защита веб-приложений и от DDoS</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Информационная безопасность</td>
            <td>
                <ul>
                    <li>Реагирование на инциденты</li>
                    <li>Предотвращение утечек данных</li>
                    <li>Поддержка ИТ-инфраструктуры</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Программная инфраструктура</td>
            <td>
                <ul>
                    <li>Предпроектное обследование</li>
                    <li>Проектирование и поставка лицензий</li>
                    <li>Масштабирование и миграция систем</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Управление контентом и документооборот</td>
            <td>
                <ul>
                    <li>Системы электронного документооборота</li>
                    <li>Цифровое взаимодействие</li>
                </ul>
            </td>
        </tr>
    
        <tr>
            <td>Финансовые и ERP-решения</td>
            <td>
                <ul>
                    <li>Автоматизация обработки данных</li>
                    <li>Внедрение ERP-систем</li>
                </ul>
            </td>
        </tr>
        
        <tr>
            <td>Заказная разработка</td>
            <td>
                <ul>
                    <li>Разработка уникальных продуктов</li>
                    <li>Высоконагруженные сайты и мобильные приложения</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Техподдержка и IT-аутсорсинг</td>
            <td>
                <ul>
                    <li>Диагностика и устранение неисправностей</li>
                    <li>Аутсорсинговые услуги</li>
                </ul>
            </td>
        </tr>
       
        <tr>
            <td>Решения для госсектора</td>
            <td>
                <ul>
                    <li>Импортонезависимые решения</li>
                    <li>Электронное правительство</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Решения для удаленной работы</td>
            <td>
                <ul>
                    <li>Настройка рабочих мест</li>
                    <li>Сервисы для безопасности данных</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Импортозамещение</td>
            <td>
                <ul>
                    <li>Отечественные решения</li>
                    <li>Обучение и сертификация</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Образование</td>
            <td>
                <ul>
                    <li>Цифровые образовательные решения</li>
                    <li>Комплексные проекты</li>
                </ul>
            </td>
        </tr>
        <tr>
            <td>Транспортные системы</td>
            <td>
                <ul>
                    <li>Управление транспортом</li>
                    <li>Фиксация нарушений</li>
                </ul>
            </td>
        </tr>
       
        </tbody>
    </table>

    )


}

export default Services
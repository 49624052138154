import { SwiperSlide } from 'swiper/react/swiper-slide';
import { Swiper } from 'swiper/react/swiper';
import React from "react";

// Import Swiper styles
import 'swiper/swiper.min.css';
import { Autoplay, Navigation, Pagination } from 'swiper';


const Card = ({name, image, link=""})=>{


    return(
        <>
        <div style={{display:"flex", justifyContent: "center", alignItems: "center", flexDirection: "column", gap: "2em"}}>
        <h1>{name}</h1>
        {link ? <a href={link} target='_blank'>
        <img src={image} width={220} height={80}/>
        </a> :  <img src={image} width={220} height={80}/> }
       
        </div>
        </>
    )   

}


export default () => {
  return (
      <>
      <h1 style={{textAlign: "center", marginTop: "1em"}}>Наши партнёры</h1>
      <div style={{padding: "3em"}}>
    <Swiper
    breakpoints={{
      
      250: {
        spaceBetween:50,
        slidesPerView:1
      },
      775: {
        spaceBetween:50,
        slidesPerView:3
      },
    }}
    modules={[Autoplay, Navigation, Pagination]}
    autoplay
      navigation
      pagination={{ clickable: true }}
      spaceBetween={50}
      slidesPerView={3}
      onSlideChange={() => console.log('slide change')}
      onSwiper={(swiper) => console.log(swiper)}
    >
      <SwiperSlide><Card name="P7" image="/img/p7.jpg" link="https://r7-office.ru/contact"/></SwiperSlide>
      <SwiperSlide><Card name="Positive Technologies" image="/img/pt.png"/></SwiperSlide>
      <SwiperSlide><Card name="Рутокен" image="/img/Rutoken.png"/></SwiperSlide>
      <SwiperSlide><Card name="Конфидент" image="/img/conf.png"/></SwiperSlide>
      <SwiperSlide><Card name="Аладдин" image="/img/alladin.png"/></SwiperSlide>
      <SwiperSlide><Card name="Код безопасности" image="/img/kb.jpg"/></SwiperSlide>
      <SwiperSlide><Card name="Эшелон" image="/img/eshelon.png"/></SwiperSlide>
      <SwiperSlide><Card name="Dr. Web" image="/img/drweb.png"/></SwiperSlide>
      <SwiperSlide><Card name="Kaspersky" image="/img/kasp.png"/></SwiperSlide>
      <SwiperSlide><Card name="Альт Линукс" image="/img/alt.png"/></SwiperSlide>
      <SwiperSlide><Card name="Астра Линукс" image="/img/astra.png"/></SwiperSlide>
          
    </Swiper>
    </div>
    </>
  );
};